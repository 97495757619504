
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { StorageServices } from '@/services/StorageServices';
import { UserClient } from '@/services/Services';
import ConversationVm from '@/components/conversationVm.vue';
import store from '@/store';
import * as OM from '@/model';

@Options({
    components: {
        ConversationVm
    },
    beforeRouteEnter(to, from, next) {
        next((vm: any) => {
            if(from.name == 'chats' || !from.name){
                vm.search = '';
                vm.allChats = StorageServices.getChatListVmList();
                vm.chats = StorageServices.getChatListVmList();
            } else {
                vm.search = StorageServices.getSocialSearch();
                let results = StorageServices.getSocialSearchResult();
                vm.users = results.users;
                vm.chats = results.conversations;
            }
        })
    }
})
export default class SocialSearch extends Vue {

    chats: OM.ChatListVm[] = [];
    users: OM.NameIdentifier[] = [];
    allChats: OM.ChatListVm[] = [];
    loading: boolean = true;
    search: string = "";

    mounted(){
        (<any>this.$refs.searchInput).focus();
        this.loading = false;
    }

    @Watch('$route.params.tab')
    onRouteParamsChange(next, prev){
        this.doSearch();
    }

    doSearch(){
        if(this.$route.params.tab == 'Chat'){
            if(!this.search.trim()){
                this.chats = this.allChats.slice();
            } else {
                this.chats = this.allChats.filter(x => x.name.toLowerCase().trim().indexOf(this.search.toLowerCase()) > -1);
            }
        } else {
            this.loading = true;
            if(!this.search.trim()){
                UserClient.getAllAsNameIdentifier()
                .then(x => {
                    this.users = x;
                }).finally(() => this.loading = false)
            } else {
                UserClient.search(this.search)
                .then(x => {
                    this.users = x;
                }).finally(() => this.loading = false)
            }
        }

    }

    openChat(item: OM.ChatRoomVm){
        StorageServices.setSocialSearch(this.search);
        StorageServices.setSocialSearchResult({
            conversations: this.chats,
            users: this.users
        });
        StorageServices.setSelectedUser(item);
        this.$router.push('/chats/' + item.identifier);
    }

    openProfile(item: OM.NameIdentifier){
        if(item.name == "Utente cancellato")
            return;
        if(store.state.user.identifier == item.identifier)
            return;
        
        StorageServices.setSocialSearch(this.search);
        StorageServices.setSocialSearchResult({
            conversations: this.chats,
            users: this.users
        });
        this.$router.push('/profile/' + item.identifier);
    }

    debouncer: any = null;
    debouncia($event: any){
        this.search = $event.target.value;
        clearTimeout(this.debouncer);
        this.debouncer = setTimeout(() => {
            this.doSearch();
        }, 300);
    }
}

