
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/model';

@Options({})
export default class ConversationVm extends Vue {

    @Prop() item: OM.ChatListVm | OM.FriendRequestRoomVm;

    @Prop({
        default: false
    }) doProfileNavigation: boolean;

    created() {
    }

    openProfile() {
        if(this.doProfileNavigation) {
            this.$router.push('/profile/' + this.item.identifier);
        }
    }

}

